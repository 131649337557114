
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    memberLevel: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultAvatar: require('@/assets/images/chat/default-avatar-03.png'), // 用户头像
    };
  },
  computed: {
    myAvatar() {
      const imgHost = process.env.NUXT_ENV.VUE_APP_STATIC_HOST;
      if (!this.avatar || this.avatar == imgHost) {
        return this.defaultAvatar;
      }
      return this.avatar;
    },
  },
};
