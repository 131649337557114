/**
 * 搜索列表模块
 * 分为 类目列表 搜索列表 品牌列表以及各种组合
 */
import CategoryList from '@/pages/search-list/CategoryList.vue'; //类目列表

import KeywordsBrand from '@/pages/search-list/KeywordsBrand.vue'; //关键字列表
import BrandList from '@/pages/search-list/BrandList.vue'; //品牌列表
import HighProfits from '@/pages/search-list/HighProfitsList.vue'; //高利润列表
import HotSellersList from '@/pages/search-list/HotSellersList.vue'; //热销商品

const searchRoutes = [
  // 类目列表
  {
    path: '/list_:id?.html',
    name: 'CategoryList',
    component: CategoryList,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },
  // 搜索加品牌列表
  {
    path: '/keywords=:keyword?',
    name: 'KeywordsList',
    component: KeywordsBrand,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },
  {
    path: '/keywords=:keyword?/:brandId?',
    name: 'KeywordsBrand',
    component: KeywordsBrand,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },
  {
    path: '/brand_:brandId?.html',
    name: 'BrandList',
    component: BrandList,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },

  // 高利润列表
  {
    path: '/highprofits.html',
    name: 'HighProfits',
    component: HighProfits,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },
  // 热销
  {
    path: '/hotsellers.html',
    name: 'HotSellersList',
    component: HotSellersList,
    meta: {
      isAuth: false,
      isRecode: true,
    },
  },
];

export default searchRoutes;
