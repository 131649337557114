import { Message } from 'element-ui';
import qs from 'qs';
import { filterReqParams } from '@/utils/req_params_filter.js';
import { getBaseURL } from '@/utils/api_modules.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default ({ $axios, app }) => {
  /**
   * 请求拦截器，设置token等
   */

  $axios.onRequest((config) => {
    // config.metadata = { startTime: new Date() }; //记录请求开始时间,以便算出响应时间
    // 这里的目的是服务端走完之后，要走客户端的话会多一个shopapi
    let baseUrl = getBaseURL(config.apiModule);
    if (config.url && !config.url.startsWith(baseUrl)) {
      // 接口路径是否有 / 前缀
      if (config.url[0] !== '/') {
        baseUrl += '/';
      }
      config.url = baseUrl + config.url;
    }
    const token = app.$cookies.get(CUR_TOKEN_NAME);
    if (config.method == 'post') {
      if (!config.data) {
        config.data = {};
      }
      config.headers = Object.assign(config.headers || {}, {
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      if (token) {
        config.headers.token = token;
      }

      filterReqParams(config.data, config.headers); // 过滤 headers 字段
      if (config.dataType === 'form-data') {
        // 处理上传文件到后端的表单数据格式
        let formData = new FormData();
        formData.append('token', token);
        for (let key of Object.keys(config.data)) {
          formData.append(key, config.data[key]);
        }
        config.data = formData;
      } else {
        config.data = qs.stringify(config.data);
      }
    } else {
      config.data = JSON.stringify(config.data);
      if (!config.params) {
        config.params = {};
      }
      config.headers = Object.assign(config.headers || {}, {
        'Content-Type': 'application/json; charset=utf-8',
      });
      if (token) {
        config.headers.token = token;
      }
      filterReqParams(config.params, config.headers); // 过滤 headers 字段
    }

    // 请求头增加来源
    config.headers.PLATFORM = 'PC';

    //判断当前请求是否设置了不显示Loading
    config.metadata = { startTime: new Date() };
    // console.log(`[API] startTime ${config.url} loaded in ${config.metadata.startTime}`);
    return config;
  });
  /**
   * 请求拦截器，error
   */
  $axios.onRequestError((error) => {
    return Promise.reject(error);
  });

  /**
   * 响应拦截器
   */
  $axios.onResponse((response) => {
    if (response) {
      let resCode = response.data ? response.data.errcode : '';
      let muteWarning = response.config?.headers?.mute_msg_warning;
      if (resCode != 0) {
        if (response.data?.msg && muteWarning != '1') {
          // 接口返回异常错误码（!= 0）时，统一在这里报错
          Message.warning(response.data.msg);
        }
      }
      const { config } = response;
      const startTime = config?.metadata?.startTime;
      const endTime = new Date();
      const duration = endTime - startTime;
      // 打印接口响应时间
      console.log(
        `[API] ${config?.url} loaded in ${duration}ms , [START] ${new Date(
          startTime
        )}`
      );
    }
    return Promise.resolve(response.data);
  });
  /**
   * 响应拦截器,error
   */
  $axios.onResponseError((error) => {
    let isTimeout = false;
    let errorMessage = '';
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          errorMessage = '错误请求';
          break;
        case 401:
          errorMessage = '未授权，请重新登录';
          break;
        case 403:
          errorMessage = '拒绝访问';
          break;
        case 404:
          errorMessage = '请求错误,未找到该资源';
          break;
        case 405:
          errorMessage = '请求方法未允许';
          break;
        case 408:
          errorMessage = '请求超时';
          break;
        case 500:
          errorMessage = '服务器端出错';
          break;
        case 501:
          errorMessage = '网络未实现';
          break;
        case 502:
          errorMessage = '网络错误';
          break;
        case 503:
          errorMessage = '服务不可用';
          break;
        case 504:
          errorMessage = '网络超时';
          break;
        case 505:
          errorMessage = 'http版本不支持该请求';
          break;
        default:
          errorMessage = `连接错误${error.response.status}`;
      }
    } else {
      isTimeout = true;
      errorMessage = '当前网络有波动，请刷新页面';
    }
    let muteError = error?.response?.config?.headers?.mute_msg_error == 1;
    if (errorMessage && !muteError) {
      // 统一弹出报错提示语
      Message.error(errorMessage);
    }

    return Promise.reject(error?.response ? error.response : error);
  });
};
