
import InfoHeader from './components/InfoHeaderItem.vue';
import SearchItem from './components/SearchItem.vue';
import GoodListItem from './components/GoodListItem.vue';
import ChatMessageItem from './components/ChatMessageItem.vue';
import EmojiPicker from './components/EmojiPicker.vue';
import CommonPhrasePicker from './components/CommonPhrasePicker.vue';
import OrderListBlock from './components/OrderListBlock.vue';
import PhraseManageDialog from './components/PhraseManageDialog.vue';
import QuoteBlock from './components/QuoteBlock.vue';

import {
  getItemBasicDetail,
  getCommonPhraseList,
  editCommonPhraseList,
  addCommonPhrase,
} from '@/api/client-api/chat/chat.js';

import {
  insertNewMessage,
  insertHistoryMessage,
  initMessageList,
  updateMsgsTimestamps,
  getCustomerServiceId,
  replaceOldMessage,
  generateRecallSysMsg,
} from '@/utils/chat_tools.js';

import {
  getMsgText,
  getMsgContent,
  getMsgType,
} from '@/utils/chat_temp_tools.js';

// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';

export default {
  components: {
    InfoHeader,
    SearchItem,
    GoodListItem,
    ChatMessageItem,
    EmojiPicker,
    CommonPhrasePicker,
    OrderListBlock,
    // GoodsListBlock,
    PhraseManageDialog,
    QuoteBlock,
  },
  props: {
    onConnect: { type: Function },
    onSyncDone: { type: Function },
    onUpdateSessions: { type: Function },
    onStickTopSessions: { type: Function },
    onSessions: { type: Function },
    onUsers: { type: Function },
    onMyInfo: { type: Function },
    onUpdateMyInfo: { type: Function },
    onUpdateUser: { type: Function },
    onDisconnect: { type: Function },
    otherId: {
      type: String,
      default: '',
    },
    // 对话对象的资料
    otherInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 当前会话对象
    currSession: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 对话对象是否有云信账号
    hasAccount: {
      type: Boolean,
      default: true,
    },
    // nim 实例连接状态
    status: {
      type: Boolean,
      default: false,
    },
    // “我”的头像
    myAvatar: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTabName: 'orders', // 当前选中的标签页
      uid: '', // 用户的id

      // 正在咨询的商品对象数据
      targetGoodProps: {
        item_id: '',
        sku_id: '',
        ware_id: '',
        title: '',
        price: '',
        imgUrl: '',
      },
      // 正在咨询的订单对象数据
      targetOrderProps: {},

      emojiBtn: require('@/assets/images/chat/chat-emoji-btn.svg'),
      imgBtn: require('@/assets/images/chat/chat-img-btn.svg'),
      phraseBtn: require('@/assets/images/chat/chat-common-phrase-icon.png'),

      msgList: [], // 刚进入页面的初始化聊天消息列表
      msgUpdateTimer: null, // 更新消息时间戳的定时器
      inputContent: '',
      recallTimeLimit: 2, // 可撤回的最大时限（2分钟内可撤回）

      msgImageLimit: 3, // 页面一次性发送的图片最大数量
      msgImageList: [], // 页面待发送的图片消息列表
      showBigImg: false, // 是否展示待发送图片的预览大图
      msgImageUrl: '', // 当前预览的图片url

      orderList: [],
      search: {
        status: '', //全部状态
        type: {}, // {key: '', value: ''}
        orderTime: [], //时间范围
        currency: '', //交易币种
      },

      pages: 1,
      pageSize: 10,

      showEmojiPicker: false,
      url: '/shopapi/Material/addMaterial',
      token: '',

      phrasesLimit: 20, // 常用语最多添加多少条
      showCommonPhrasePicker: false, // 常用语选择列表
      showCommonPhraseDialog: false, // 常用语管理弹窗
      phraseList: [], // 常用语列表
      unsavedPhraseList: [], // 待保存的常用语列表
      showPhrasesSaveDialog: false, // 保存常用语的二次确认弹窗
      showPhrasesCancelDialog: false, // 退出常用语的二次确认弹窗

      loadingMsgs: false, // 会话窗口的加载动效
      loadingMore: false, // 点击加载更多单独的加载动效
      boxHeightBefore: 0, // 加载前的窗口元素高度
      hitTop: false, // 是否已加载完全部的聊天消息

      showQuoteBlock: false, // 是否展示输入框上方的引用框
      quoteMsg: null, // 引用的消息对象
      quoteType: '', // 引用的消息的类型
      quoteInfo: null, // 引用的消息的对象
      quoteContent: '', // 引用的消息的内容
    };
  },
  watch: {
    status: async function (newVal) {
      if (newVal && this.hasAccount) {
        await this.initMsgList();
      }
    },
  },
  computed: {
    // 当前的会话id
    currSessionId() {
      if (!this.otherId) {
        return '';
      }
      return `p2p-${this.otherId}`;
    },
    anotherName() {
      return this.hasAccount ? this.otherInfo?.nick : this.otherId;
    },
    anotherAvatar() {
      return this.hasAccount ? this.otherInfo?.avatar : '';
    },
    // #memo 为实现关闭或者点击二次确认弹窗的取消按钮后
    // 管理弹窗保存编辑数据的效果，用 unsavedPhraseList 作缓存
    manageBlockPhrases() {
      return this.unsavedPhraseList?.length > 0
        ? this.unsavedPhraseList
        : this.phraseList;
    },
    isCustomerService() {
      const customerServiceId = getCustomerServiceId();
      return this.otherId == customerServiceId;
    },
  },
  methods: {
    // 清除字符串内的空白字符
    removeSpaces(str) {
      str = str.replace(/^\s*|\s*$/g, '').replace(/[\r\n]+/g, '');
      return str;
    },
    // 获取用户头像
    getAvatar(from) {
      let avatar = '';
      if (from === this.uid) {
        avatar = this.myAvatar;
      } else if (from === this.otherId) {
        avatar = this.anotherAvatar;
      }
      return avatar;
    },
    // 获取消息类型
    getMsgType(msg) {
      return getMsgType(msg);
    },
    // 获取消息内容
    getMsgText(msg) {
      let quoteMsg = this.getQuoteMsg(msg);
      return quoteMsg ? getMsgContent(msg)?.content : getMsgText(msg, this.uid);
    },
    // 获取消息数据对象
    getMsgContent(msg) {
      return getMsgContent(msg);
    },
    // 获取引用消息
    getQuoteMsg(msg) {
      let type = this.getMsgType(msg);
      if (type === 'quote') {
        return this.getMsgContent(msg)?.quoteMsg;
      }
      return null;
    },
    // 清空引用相关的数据
    clearQuoteData() {
      this.quoteType = '';
      this.quoteInfo = null;
      this.quoteMsg = null;
      this.quoteContent = '';
    },
    /**
     * 定时器
     */
    // 更新自定义时间戳的定时器
    startMsgUpdateTimer() {
      if (this.msgUpdateTimer) {
        this.msgUpdateTimer = null;
      }

      this.msgUpdateTimer = setInterval(() => {
        this.$store.commit('chat/updateMsgsTimeStamp', this.currSessionId);
        updateMsgsTimestamps(this.msgList);
      }, 30000);
    },
    // 关闭自定义时间戳的定时器
    closeMsgUpdateTimer() {
      if (this.msgUpdateTimer) {
        clearInterval(this.msgUpdateTimer);
      }
    },

    /**
     * 工具函数
     */

    // 滚动条到底部
    scrollToEnd() {
      if (process.client) {
        this.$nextTick(() => {
          let box = this.$refs.chatBox;
          if (box) {
            box.scrollTop = box?.scrollHeight;
          }
        });
      }
    },
    async sendMsg(data) {
      try {
        let msg = await this.$store.dispatch('chat/sendMessage', data);

        if (data.type === 'recall') {
          replaceOldMessage(this.msgList, data.originalMsg, msg);
        } else {
          insertNewMessage(this.msgList, msg, this.uid);
        }
      } catch (err) {
        // 发送消息失败
        if (this.isLocalEnv()) {
          console.error(err);
        }
        insertNewMessage(this.msgList, err);
      }
      this.scrollToEnd();
      if (process.client) {
        this.$nextTick(() => {
          if (this.$refs.chatBox) {
            // 更新聊天窗口的高度
            this.boxHeightBefore = this.$refs.chatBox?.scrollHeight;
          }
        });
      }
    },
    // 初始化正在咨询
    initConsultBlock() {
      let queryType = this.$route.query.type;
      if (queryType === 'good') {
        this.targetGoodProps.item_id = this.$route.query.item_id;
        this.targetGoodProps.sku_id = this.$route.query.sku_id;
        this.targetGoodProps.ware_id = this.$route.query.ware_id;

        this.getGoodDetail();
      }
    },
    initMsgList() {
      return new Promise(async (resolve, reject) => {
        if (!this.status || !this.hasAccount) return resolve();
        // 初始化聊天消息列表
        this.loadingMsgs = true;
        try {
          let msgs = await this.$store.dispatch('chat/fetchHistoryMsgs', {
            id: this.otherId,
            type: 'cloud',
            isInit: true, // 是否是初始化聊天列表
          });
          this.msgList = initMessageList(msgs); // 加时间戳
          this.startMsgUpdateTimer();
          this.scrollToEnd();
          this.loadingMsgs = false;
          if (process.client) {
            this.$nextTick(() => {
              if (this.$refs.chatBox) {
                // 初始化聊天窗口的高度
                this.boxHeightBefore = this.$refs.chatBox.scrollHeight;
              }
            });
          }
          return resolve();
        } catch (err) {
          console.error(err);
          this.loadingMsgs = false;
          return reject(err);
        }
      });
    },
    /**
     * 获取商品信息
     */
    async getGoodDetail() {
      try {
        // #memo 限制的账号不给发送请求或跳转页面（限制：冻结 or 关闭）
        if (this.hasAccountLimited()) {
          return;
        }
        const options = {
          sku_id: this.targetGoodProps.sku_id,
          ware_id: this.targetGoodProps.ware_id,
        };
        const res = await getItemBasicDetail(options);
        if (res?.errcode == 0) {
          this.targetGoodProps.title = res.data.title;
          this.targetGoodProps.imgUrl = res.data.image_default_id;
          this.targetGoodProps.price = res.data.favorable_price;
          this.activeTabName = 'goods';
        }
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * 页面触发
     */

    // 点击撤回
    async handleClickRecall(msg) {
      try {
        // 执行撤回操作
        const res = await this.$nim.recallMessage(msg);
        // 发送撤回提示消息
        const recallMsg = {
          to: this.otherId?.toLowerCase(),
          from: this.uid,
          type: 'recall',
          originalMsg: res.originalMsg,
        };
        await this.sendMsg(recallMsg);
      } catch (err) {
        let moreText = '';
        if (err.code == 508) {
          moreText = `已超过撤回时限（${this.recallTimeLimit}分钟）`;
        }
        this.$message.warning(`消息撤回失败${moreText ? '，' + moreText : ''}`);
      }
    },
    // 点击添加常用语
    async handleClickAddCommonPhrase(msg) {
      try {
        // #memo 限制的账号不给发送请求或跳转页面（限制：冻结 or 关闭）
        if (this.hasAccountLimited()) {
          return;
        }
        if (this.phraseList?.length >= this.phrasesLimit) {
          this.$message.warning(`最多添加${this.phrasesLimit}条常用语`);
          return;
        }
        if (!msg?.text || msg.content) return;
        const options = { content: msg.text, MUTE_WARNING: 1 };
        const res = await addCommonPhrase(options);
        if (res?.errcode == 0) {
          this.fetchCommonPhrases();
          this.$message.success('添加成功');
        } else if (res?.msg) {
          this.$message.error(res.msg);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 点击引用
    handleClickReply(msg) {
      if (!msg) return;
      let msgInfo = msg.content ? JSON.parse(msg.content) : null;
      this.quoteMsg = msg;
      if (!msgInfo) {
        this.quoteType = 'text';
        this.quoteContent = msg.text;
      } else {
        this.quoteType =
          msgInfo.type === 'link' ? msgInfo.dataType : msgInfo.type;
      }
      if (this.quoteType === 'image') {
        this.quoteContent = msgInfo?.content?.url;
      } else if (this.quoteType === 'order' || this.quoteType === 'good') {
        this.quoteInfo = msgInfo.content;
      }

      console.log('被点击引用的消息', msg);
      this.showQuoteBlock = true;
    },
    // 粘贴图片
    handlePasteImage(imgUrl) {
      if (!imgUrl || this.msgImageList?.length >= this.msgImageLimit) return;
      this.msgImageList.push(imgUrl);
    },
    // 点击图片，预览大图
    handleClickPreviewImage(imgUrl) {
      this.msgImageUrl = imgUrl;
      this.showBigImg = true;
    },
    // 点击删除待发送图片
    handleClickDeleteImage(idx) {
      this.msgImageList.splice(idx, 1);
    },
    // 阻止敲下 enter 键后输入回车
    handleClickEnter(e) {
      let et = e || window.event;
      let keycode = et.charCode || et.keyCode;
      if (keycode == 13) {
        if (window.event) {
          window.event.returnValue = false;
        } else {
          e.preventDefault();
        }
      }
    },
    // 点击发送
    handleClickSend() {
      if (this.msgImageList?.length > 0) {
        for (let img of this.msgImageList) {
          const data = {
            to: this.otherId?.toLowerCase(),
            from: this.uid,
            type: 'image',
            content: {
              url: img,
            },
          };
          this.sendMsg(data);
        }
      }
      this.msgImageList = [];
      let afterRemoveSpacesStr = this.removeSpaces(this.inputContent);
      if (this.inputContent && afterRemoveSpacesStr) {
        let content = this.inputContent.replace(/\n$/g, '');
        const data = {
          to: this.otherId?.toLowerCase(),
          from: this.uid,
          type: 'text',
          content: content,
        };
        if (this.showQuoteBlock) {
          data.type = 'quote';
          data.quoteMsg = this.quoteMsg;
        }
        this.sendMsg(data);
        this.inputContent = '';
        this.showEmojiPicker = false;
        this.showCommonPhrasePicker = false;
        // 清空引用消息数据
        this.showQuoteBlock = false;
        this.clearQuoteData();
      }
      this.inputContent = '';
    },
    // 点击发送正在咨询中的商品卡片
    handleClickSendGoods(item) {
      if (!item) return;
      item.image_default_id = item.imgUrl;
      const data = {
        to: this.otherId?.toLowerCase(),
        from: this.uid?.toLowerCase(),
        type: 'link',
        content: item,
        dataType: 'good', // 当前发送的卡片是订单（order）还是商品（good）
        price: item.price, // 发送订单的总价格
      };
      this.sendMsg(data);
    },
    // 点击订单卡片或者商品卡片
    handleClickSendCard(data) {
      if (!data) return;
      this.sendMsg(data);
    },
    // 点击 emoji 图标
    handleClickEmojiBtn() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    // 选中一个 emoji
    handleClickEmoji(item) {
      this.inputContent += item;
    },
    // 关闭 emoji picker
    handleCloseEmojiPicker() {
      this.showEmojiPicker = false;
    },
    // 图片发送之前，检查文件类型
    handleBeforeUploadImg(file) {
      let fileType = this.matchType(file.name);
      let isImg = fileType === 'image';
      if (!isImg) {
        this.$message.warning('仅支持上传图片');
      }
      return isImg;
    },
    // 将图片消息定义为自定义类型信息
    // 通过xm的图片服务器，显示在消息窗口
    // 不通过云信的图片发送api
    handleImgUploadSuccess(res, file) {
      if (res?.errcode == 0) {
        let url = res.data.link;
        if (!url.startsWith('http')) {
          url = process.env.NUXT_ENV.VUE_APP_STATIC_HOST + url;
        }
        this.msgImageList.push(url);
      }
    },
    // 图片发送失败
    handleImgUploadError(err) {
      console.error(err);
      this.$message.warning('图片发送失败');
    },

    /**
     * 常用语模块
     */
    // 点击常用语弹窗中的某行常用语
    handleClickPhrase(phrase) {
      if (!phrase) return;
      this.inputContent = phrase; // #memo 常用语直接覆盖掉输入框中原本的输入
    },
    // 点击打开常用语管理弹窗
    handleOpenCommonPhraseDialog() {
      this.showCommonPhraseDialog = true;
    },
    // 点击常用语图标
    handleClickCommonPhraseIcon() {
      this.showCommonPhrasePicker = !this.showCommonPhrasePicker;
      if (this.showCommonPhrasePicker) {
        this.showEmojiPicker = false;
      }
    },
    // 关闭常用语管理 popup
    handleCloseCommonPhrasePopup() {
      this.showCommonPhrasePicker = false;
    },
    // 点击常用语管理弹窗的取消按钮，缓存编辑数据并打开二次确认弹窗
    handleClickCancelCommonPhraseDialog(list) {
      this.unsavedPhraseList = list?.slice() || [];
      this.showPhrasesCancelDialog = true;
    },
    // 点击常用语管理弹窗的确认按钮，缓存编辑数据并打开二次确认弹窗
    handleClickSaveCommonPhraseDialog(list) {
      this.unsavedPhraseList = list?.slice() || [];
      this.showPhrasesSaveDialog = true;
    },
    // 已经二次确认取消常用语管理的编辑
    handleConfirmPhrasesCancelDialog() {
      this.unsavedPhraseList = [];
      this.showPhrasesCancelDialog = false;
      this.showCommonPhraseDialog = false;
      this.fetchCommonPhrases(); // 更新获取常用语列表，避免关闭管理弹窗后再打开，弹窗列表数据不更新
    },
    // 已经二次确认保存常用语管理的编辑
    async handleConfirmPhrasesSaveDialog() {
      try {
        await this.postCommonPhrases(this.unsavedPhraseList);
      } catch (err) {
        console.error(err);
      }
      this.unsavedPhraseList = [];
      this.fetchCommonPhrases(); // 更新获取常用语列表
      this.showPhrasesSaveDialog = false;
      this.showCommonPhraseDialog = false;
    },
    // 二次确认时取消退出管理（返回常用语管理弹窗）
    handleClosePhrasesCancelDialog() {
      this.showPhrasesCancelDialog = false;
    },
    // 二次确认时取消保存编辑（返回常用语管理弹窗）
    handleClosePhrasesSaveDialog() {
      this.showPhrasesSaveDialog = false;
    },

    /**
     * 引用模块
     */
    // 点击引用板块的关闭按钮
    handleClickCloseQuote() {
      this.showQuoteBlock = false;
      this.clearQuoteData();
    },

    // 点击加载更多消息
    async handleClickLoadMoreMsgs() {
      if (!this.status || !this.hasAccount) return;
      try {
        // 初始化聊天消息列表
        this.loadingMore = true;
        const historyMsgs = await this.$store.dispatch(
          'chat/fetchHistoryMsgs',
          {
            id: this.otherId,
            type: 'cloud',
            isInit: false, // 是否是初始化聊天列表
            limit: 25, // 一次加载多少条消息
          }
        );
        if (historyMsgs.length > 0) {
          setTimeout(() => {
            let lastMsg = this.msgList?.[0],
              lastTimeMsgStamp = 0;
            for (let msg of this.msgList) {
              if (msg.from === 'sys-custom-time') {
                lastTimeMsgStamp = msg.time;
                break;
              }
            }
            for (let i = historyMsgs.length - 1; i >= 0; i--) {
              let msg = historyMsgs[i];
              // 跳过时间比 msgList 最旧消息时间更新的
              if (msg.time >= this.msgList?.[0].time) continue;

              let insertTimeMsg =
                !lastMsg ||
                (lastMsg.from !== 'sys-custom-time' &&
                  (i === 0 ||
                    lastMsg.time - msg.time > 2 * 60 * 1000 ||
                    lastTimeMsgStamp - msg.time > 2 * 60 * 1000));
              insertHistoryMessage(
                this.msgList,
                historyMsgs[i],
                insertTimeMsg,
                i === historyMsgs.length - 1 || i === 0
              );
              if (insertTimeMsg) lastTimeMsgStamp = msg.time;
              lastMsg = msg;
            }
            this.loadingMore = false;
            // 保证视口在刚刚点击“点击加载更多”的消息的高度
            if (process.client) {
              this.$nextTick(() => {
                if (this.$refs.chatBox) {
                  let boxHeightNow = this.$refs.chatBox.scrollHeight;
                  let boxHeightBefore = this.boxHeightBefore;
                  this.$refs.chatBox.scrollTop = boxHeightNow - boxHeightBefore;
                  this.boxHeightBefore = boxHeightNow;
                }
              });
            }
          }, 500);
        } else {
          setTimeout(() => {
            this.loadingMore = false;
            this.hitTop = true;

            this.$nextTick(() => {
              this.$refs.chatBox.scrollTop = 0;
            });
          }, 500);
        }
      } catch (err) {
        console.error(err);
        this.loadingMore = false;
      }
    },
    // 我收到新消息
    async onMsg(res) {
      if (this.isLocalEnv()) {
        console.log('【收到新消息】', res);
      }
      this.$store.dispatch('chat/receiveMessage', res);
      if (res.sessionId === this.currSessionId) {
        if (res.type === 'tip') {
          /**
           * #memo 这里和 onsysmsg 里的替换都必须做，因为：
           * 1. 如果仅在 onsysmsg 中做替换，则必须在这里做插入撤回通知，同时要么
           *    （因为被撤回的消息不一定是最新消息）对整个消息列表进行排序 o(nlogn)，
           *    要么从尾到头枚举撤回消息插入的位置 o(n)，效率差；
           * 2. 如果仅在这里做替换，当出现一种情况：撤回执行成功，但撤回消息发送失败，
           *    则会导致被撤回消息在收方的错误展示
           * 最后采用第三种方法：
           * 在 onsysmsg 中替换被撤回消息；并在发送撤回提示消息之后再替换一次
           */
          const originalMsg = JSON.parse(res.custom || '{}')?.originalMsg;
          // #memo 这里需要特殊处理一下 sessionId
          // 因为分销商会涉及同步 pc 和 app 消息列表的问题
          // 假设在 app 发送了一条撤回消息，originalMsg 中的 sessionId 将会置为消息接收方端的会话id
          // 这是为了保证在接收端正常显示；而会导致 pc 端的会话id错误
          res.time = originalMsg.time;
          this.$nim.resetMsgSessionId(originalMsg);
          replaceOldMessage(this.msgList, originalMsg, res);
        } else {
          insertNewMessage(this.msgList, res);
        }
      }
      this.scrollToEnd();
      if (process.client) {
        this.$nextTick(() => {
          if (this.$refs.chatBox) {
            // 更新聊天窗口的高度
            this.boxHeightBefore = this.$refs.chatBox?.scrollHeight;
          }
        });
      }
    },
    async onSystemMsg(res) {
      if (this.isLocalEnv()) {
        console.log('【收到系统消息】', res);
      }
      // 是当前会话对象撤回消息，才执行撤回
      if (res.type === 'deleteMsg' && res.from === this.otherId) {
        let hintMsg = generateRecallSysMsg(res.msg, this.uid);
        this.$store.commit('chat/replaceMsgById', res.msg, hintMsg);
        replaceOldMessage(this.msgList, res.msg, hintMsg);
      }
    },

    // 获取常用语列表
    async fetchCommonPhrases() {
      try {
        // #memo 限制的账号不给发送请求或跳转页面（限制：冻结 or 关闭）
        if (this.hasAccountLimited()) {
          return;
        }
        const res = await getCommonPhraseList();
        if (res?.errcode == 0) {
          this.phraseList = res.data || [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 更新常用语列表
    postCommonPhrases(list) {
      // #memo 限制的账号不给发送请求或跳转页面（限制：冻结 or 关闭）
      if (this.hasAccountLimited()) {
        return;
      }
      return new Promise(async (resolve, reject) => {
        try {
          const res = await editCommonPhraseList({ data: list });
          if (res?.errcode == 0) {
            this.$message.success('修改成功');
            resolve();
          } else {
            reject();
          }
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    },
  },
  async mounted() {
    // 初始化我的id 和昵称
    this.uid = this.$nim?.account?.toLowerCase();
    this.token =
      localStorage.getItem(CUR_TOKEN_NAME) ||
      window.$nuxt.context.$cookies.get(CUR_TOKEN_NAME);
    this.fetchCommonPhrases();
    if (this.status && this.hasAccount) {
      await this.initMsgList();
    }
    this.initConsultBlock();
    const nimOptions = {
      onconnect: this.onConnect,
      onsyncdone: this.onSyncDone,
      onupdatesessions: this.onUpdateSessions,
      onStickTopSessions: this.onStickTopSessions,
      onsessions: this.onSessions,
      onusers: this.onUsers,
      onmyinfo: this.onMyInfo,
      onupdatemyinfo: this.onUpdateMyInfo,
      onupdateuser: this.onUpdateUser,
      ondisconnect: this.onDisconnect,
      onmsg: this.onMsg,
      onsysmsg: this.onSystemMsg,
    };
    this.$nim.init(nimOptions);
  },
  beforeDestroy() {
    this.closeMsgUpdateTimer();
    this.inputContent = '';
  },
};
