// 自定义活动
import request from '@/utils/request.js';

// 自定义活动页面数据
export function getDetail(data) {
  return request({
    url: '/customizeActivity/detail',
    apiModule:'activity',
    method: 'get',
    params: data,
  });
}

// 板块列表数据
export function getPlateList(data) {
  return request({
    url: '/customizeActivity/plateList',
    apiModule:'activity',
    method: 'get',
    params: data,
  });
}

// 板块列表数据
export function getPlateItemList(data) {
  return request({
    url: '/customizeActivity/plateItemList',
    apiModule:'activity',
    method: 'get',
    params: data,
  });
}

// 获取商品板块列表（活动页侧边栏）
export function getPlateBarList(data) {
  return request({
    url: '/customizeActivity/plateBarList',
    apiModule:'activity',
    method: 'get',
    params: data,
  });
}
