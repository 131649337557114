
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
export default {
  name: 'BasicGoodsBox',
  props: {
    // 商品信息
    goodsMsg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      token: '',
    };
  },
  computed: {
    // 图片懒加载
    imgURL() {
      if (this.goodsMsg?.image_default_id) {
        return this.changeImgProperties(this.goodsMsg?.image_default_id);
      }
    },
  },
  mounted() {
    this.token = this.$cookies.get(CUR_TOKEN_NAME);
  },
  methods: {},
};
