
import stepActive from './components/stepActive.vue';
import suppleDetail from './components/suppleDetail.vue';
import confirmationInformation from './components/confirmationInformation.vue';
import success from './components/success.vue';
import { getBasicInformation } from '@/api/client-api/account/accountmanage.js';
import { getUserDetailInfo } from '@/api/client-api/account/information';
export default {
  components: {
    stepActive,
    suppleDetail,
    confirmationInformation,
    success,
  },
  data() {
    return {
      activeStep: 1, // 当前步骤
      withdrawObj: {}, // 提现相关信息
      netAmount: '', // 净金额
      commission: '', // 手续费
      realName: '', // 收款支付宝姓名
      phoneShow: '', // 隐藏的手机号
      phone: '', // 手机号

      activeTab: '-1', // -1个人认证 or 1企业认证
    };
  },
  mounted() {
    this.getBaseDeatl();
    this.getUserInfo();
  },
  methods: {
    activeSecond(obj, netAmount, commission) {
      this.withdrawObj = obj;
      this.netAmount = netAmount;
      this.commission = commission;
      this.activeStep = 2; // 第二步
    },
    // 获取实名认证信息
    getBaseDeatl() {
      getBasicInformation()
        .then((res) => {
          if (res.errcode == 0) {
            // this.realName = res.data.name;
            this.phoneShow = res.data.phone_show;
            this.phone = res.data.phone;

            let env = process?.env?.NUXT_ENV?.NODE_ENV;
            const limitEnv = [
              'test',
              'development',
              'release',
              'check',
              'custom', // #memo 补充 194 环境提现限制
            ];
            const allowPhone = {
              test: '15323413113',
              development: '15323413113',
              release: '15323413113',
              check: '15549898735',
              custom: '15549898735', // #memo 补充 194 环境提现限制
            };
            if (limitEnv.includes(env) && this.phone != allowPhone[env]) {
              this.$router.push('/fund/fundcenter/1');
              this.$message.warning('当前账号不可提现');
              return;
            }
            // if (res?.data?.is_enterprise == "1") this.activeTab = "2"; // 1企业 -1个人
          }
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    },
    // 获取认证信息
    async getUserInfo() {
      try {
        const res = await getUserDetailInfo();
        if (res?.errcode == 0) {
          this.activeTab = res?.data?.is_enterprise;
          if (this.activeTab == 1) {
            // 企业用户,默认个人账户
            this.realName = res.data.com_name;
          } else {
            this.realName = res.data.name;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    // 第三步
    activeThree() {
      this.activeStep = 3; // 第三步
    },
    // 返回第一步
    backpro() {
      this.activeStep = 1; // 第三步
    },
  },
};
