
export default {
  props: {
    advertiseList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    curAdvertiseList() {
      return this.advertiseList || [];
    },
  },
  methods: {
    /**
     * 页面触发
     */
    goUrl(item) {
      if (!item.plate_link) return;
      window.open(item.plate_link);
    },
  },
};
