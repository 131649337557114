export default ({ $axios }, inject) => {
  inject('itemdetail', (data) =>
    $axios({
      url: '/Item/itemInfo',
      apiModule: 'goods',
      method: 'get',
      apiModule: 'goods',
      params: data,
    })
  );
  inject('getGoodScores', (data) =>
    $axios({
      url: '/item/getItemScore',
      method: 'get',
      params: data,
    })
  );
  inject('addFav', (data) =>
    $axios({
      apiModule: 'goods',
      url: '/fav/addFav',
      method: 'get',
      params: data,
    })
  );
  inject('deleteFav', (data) =>
    $axios({
      apiModule: 'goods',
      url: '/fav/deleteFav',
      method: 'get',
      params: data,
    })
  );
  inject('getItemWarehouses', (data) =>
    $axios({
      apiModule: 'ware',
      url: '/warehouseGoods/getItemWarehouses',
      method: 'get',
      params: data,
    })
  );
  inject('getItemFreight', (data) =>
    $axios({
      url: '/ShopTrade/getItemFreight',
      method: 'get',
      params: data,
    })
  );
  inject('getChangeLog', (data) =>
    $axios({
      url: '/item/getChangeLog',
      method: 'get',
      params: data,
    })
  );
  inject('getGoodsEvaluateList', (data) =>
    $axios({
      url: 'evaluate/getGoodsEvaluateList',
      method: 'get',
      params: data,
    })
  );
};
// // 商城首页
// import request from '@/utils/request.js';

// // 获取热门排行
// export function getPopularRanking(data) {
//   return request({
//     url: '/Item/getPopularRanking',
//     method: 'get',
//     params: data,
//   });
// }
// //获取爆款商品列表
// export function getHotItemList(data) {
//   return request({
//     url: '/Item/getHotItemList',
//     method: 'get',
//     params: data,
//   });
// }
// //获取智能推荐（千人千面）商品列表
// export function getRecommendItemList(data) {
//   return request({
//     url: '/Item/getRecommendItemList',
//     method: 'get',
//     params: data,
//   });
// }
// //获取所有商品分类(树状)
// /* export function getCategoryTree(data) {
//     return request({
//       url: "/Category/getCategoryTree",
//       method: "get",
//       params:data
//     });
//   } */
// export function getCategoryTree(data) {
//   return request({
//     url: 'Category/getAllCategoryInfo',
//     method: 'get',
//     params: data,
//   });
// }
// //获取首页轮播图列表
// export function getCarouselList(data) {
//   return request({
//     url: '/index/getCarouselList',
//     method: 'get',
//     params: data,
//   });
// }

// //商品详情
// export function itemdetail(data) {
//   return request({
//     url: '/item/index',
//     method: 'get',
//     params: data,
//   });
// }

// //跳转支付
// export function jumpPayment(data) {
//   return request({
//     url: '/order/jumpPayment',
//     method: 'get',
//     params: data,
//   });
// }
// //获取仓库信息
// export function getItemWarehouses(data) {
//   return request({
//     apiModule: 'ware',
//     url: '/warehouseGoods/getItemWarehouses',
//     method: 'get',
//     params: data,
//   });
// }
// //获取物流模板
// export function getItemFreight(data) {
//   return request({
//     url: '/ShopTrade/getItemFreight',
//     method: 'get',
//     params: data,
//   });
// }
// //下单
// export function addUserOrder(data) {
//   return request({
//     url: '/ShopTrade/addUserOrder',
//     method: 'post',
//     data: data,
//   });
// }

// /* export function addItemNeedNew(data) {
//     return request({
//       url: "/ItemNeedNew/add",
//       method: "post",
//       data:data
//     });
//   } */

// // 查询商品退货政策
// export function getItemPolicy(data) {
//   return request({
//     url: 'item/getItemPolicy',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取商品评价
// export function getGoodsEvaluateList(data) {
//   return request({
//     url: 'evaluate/getGoodsEvaluateList',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取当日汇率
// export function getExchange(data) {
//   return request({
//     url: '/MoneyManage/getExchange',
//     method: 'get',
//     params: data,
//   });
// }

// // 点击收藏商品
// export function addFav(data) {
//   return request({
//     url: '/fav/addFav',
//     method: 'get',
//     params: data,
//   });
// }

// // 点击取消商品收藏
// export function deleteFav(data) {
//   return request({
//     url: '/fav/deleteFav',
//     method: 'get',
//     params: data,
//   });
// }

// // 添加新品需求
// export function addNewItemNeed(data) {
//   return request({
//     url: '/ItemNeed/addNewItemNeed',
//     method: 'post',
//     data: data,
//   });
// }
// // 商品资源下载
// export function itemResourceDownload(data) {
//   return request({
//     url: '/fav/itemResourceDownload',
//     method: 'get',
//     params: data,
//   });
// }
// //
// export function exportInventoryResource(data) {
//   return request({
//     url: '/item/exportInventoryResource',
//     method: 'get',
//     params: data,
//   });
// }
// // 获取下载资源文件
// export function getFileName(data) {
//   return request({
//     url: '/item/getMaterialName',
//     method: 'get',
//     params: data,
//   });
// }

// /**
//  * 获取指定仓库下的指定sku的操作费计算规则
//  * @param {*} data
//  * @returns
//  */
// export function getGoodOperationFeeRule(data) {
//   return request({
//     apiModule: 'ware',
//     url: '/warehouseGoods/getGoodsOperatingFeeRule',
//     method: 'get',
//     params: data,
//   });
// }
// // 获取指定商品的变更通知
// export function getChangeLog(data) {
//   return request({
//     url: '/item/getChangeLog',
//     method: 'get',
//     params: data,
//   });
// }

// // 获取指定商品的商品、服务、物流评分
// export function getGoodScores(data) {
//   return request({
//     url: '/item/getItemScore',
//     method: 'get',
//     params: data,
//   });
// }
// // 获取热搜词
// export function getHotKeywords(data) {
//   return request({
//     url: '/item/getHotKeyword',
//     method: 'get',
//     params: data,
//   });
// }
