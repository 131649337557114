import Vue from 'vue';
import { NimChat } from '@/utils/NimChat';
// import { getPoint } from '@/api/api';
import { getCategoryInfo } from '@/api/client-api/my-platform/platform';
import { startDetector } from '@/utils/auto_logout';
import { setLastVisitedRoute } from '@/utils/history_recorder';
// 跨域消息传递
import { getPostMessage } from '@/utils/post-message/postmessage.js';
// 跨域名登录
import { getCrossDomainLoginInfo } from '@/utils/cross-domain-login/cross_domain_login.js';
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
// 埋点
function burialPoint(routePath, status, enterTime, app) {
  let stayRoute = '';
  // 除开首页,商品详情页,商品分类列表页,店铺列表页
  if (
    routePath.name != 'home' &&
    routePath.name != 'shopdetail' &&
    routePath.name != 'goodsClassificat' &&
    routePath.name != 'ShopList' &&
    routePath.name != null
  ) {
    // 判断是不是params传参方式 ===0可能不是,!==0是
    if (Object.entries(routePath.params).length !== 0) {
      let realPath = routePath.matched[routePath.matched.length - 1].path;
      // 判断有没有params传参 indexOf(':')=== -1没有 !== -1有
      if (realPath.indexOf(':') !== -1) {
        stayRoute = realPath.slice(0, realPath.indexOf(':') - 1);
      } else {
        stayRoute = realPath;
      }
    } else {
      stayRoute = routePath.path;
    }
  } else {
    return;
  }
  // 获取操作系统
  let leftMarkIndex = navigator.userAgent.indexOf('(') + 1;
  let rightMarkIndex = navigator.userAgent.indexOf(';');
  let operatingSystem = navigator.userAgent.slice(
    leftMarkIndex,
    rightMarkIndex
  );
  let dataList = [
    {
      operating_system: operatingSystem, // 操作系统
      route: stayRoute, // 停留页面路由
    },
  ];
  if (status === 'before') {
    dataList[0].time_in = enterTime; // 进入页面时间
  }
  if (status === 'after') {
    dataList[0].time_in = enterTime; // 进入页面时间
    dataList[0].time_out = Date.now(); // 跳出页面时间
  }
  let options = {
    data: dataList,
    MUTE_WARNING: 1,
    MUTE_ERROR: 1,
  };
  if (process.client) {
    app.$getPoint(options).then((res) => {
      // if (res.errcode === 0) {
      //   // console.log('这是埋点打印出来的:', res.msg);
      // } else {
      //   // console.log('埋点报错:', res.msg);
      // }
    });
  }
}

export default ({ app }) => {
  let enterTime = null;

  app.router.beforeEach((to, from, next) => {
    if (process.client) {
      getCrossDomainLoginInfo(app);
    }

    let token = app.$cookies.get(CUR_TOKEN_NAME);
    // todo 修改状态到时放到vuex
    let status = app.$cookies.get('statusShow');
    enterTime = Date.now();
    burialPoint(to, 'before', enterTime, app);

    if (process.client) {
      // 跨域传递信息/浏览器记录
      getPostMessage(to);
    }
    // 需要登录且有 token（有登录状态）
    if (token) {
      let isToChat = to.path?.indexOf('/chat') >= 0; // 是否是跳转聊天页面
      let isToWaitPage = to.path?.indexOf('/waitpage') >= 0; // 是否是登录中转页

      if (!Vue.prototype.$nim) {
        const userdetail = app.$cookies.get('userdetail');
        if (userdetail) {
          const uid = userdetail.user_id;
          if (uid && uid.length > 0) {
            let IMToken = userdetail.im_token;
            Vue.prototype.$nim = new NimChat(uid, IMToken);
            if (Vue.prototype.$nim.isValid && !isToChat) {
              Vue.prototype.$nim.init();
            }
          }
        }
      }
      // 初始化监听用户动态操作的检测器
      if (!Vue.prototype.$dynamicDetector) {
        Vue.prototype.$dynamicDetector = startDetector();
      }

      let categoryInfo = [];
      categoryInfo = app.store.state.category.categoryData;
      if (categoryInfo?.length === 0 && (!isToChat || !isToWaitPage)) {
        // 跳转聊天页面 或 登录中转页，不请求类目接口
        getCategoryInfo({ MUTE_WARNING: 1, MUTE_ERROR: 1 })
          .then((res) => {
            if (res.errcode == 0) {
              app.store.dispatch('category/setCategoryData', res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      let categoryEnInfo = [];
      categoryEnInfo = app.store.state.category.categoryEnData;
      // 存英文类目
      if (categoryEnInfo?.length === 0 && (!isToChat || !isToWaitPage)) {
        // 跳转聊天页面 或 登录中转页，不请求类目接口
        getCategoryInfo({ language: '3', MUTE_WARNING: 1, MUTE_ERROR: 1 })
          .then((res) => {
            if (res.errcode == 0) {
              app.store.dispatch('category/setCategoryEnData', res.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      // 关闭检测用户动态操作的检测器
      if (Vue.prototype.$dynamicDetector) {
        Vue.prototype.$dynamicDetector.close();
        Vue.prototype.$dynamicDetector = null;
      }
      // 销毁 nim 实例
      if (Vue.prototype.$nim) {
        Vue.prototype.$nim.destroy();
        Vue.prototype.$nim = null;
      }
      app.store.commit('login/clearLoginInfoForm');
      /**
       * #TODO 这个本来是扔在if判断外面的，但是出了问题所以扔进来
       * 原因：放在外面登录成功后回到商品详情页又会进行记录，而且存储在 localStorage 中，只有登录成功才会清，那么每一次登录都回回到记录页面了，并且无限制
       * 目前只有登录的时候用到了，所以没啥影响
       */
      if (to.meta.isRecode) {
        // 特殊处理列表页，详情页，搜索页
        setLastVisitedRoute(to); // 记录用户最新浏览的页面路径
      }
    }

    if (!to.meta.isAuth) {
      console.log('auth, 不需要登录');
      // 不需要登录
      next();
    } else {
      if (!token) {
        console.log('auth, 需要登录，未登录');
        // 关闭检测用户动态操作的检测器
        if (Vue.prototype.$dynamicDetector) {
          Vue.prototype.$dynamicDetector.close();
          Vue.prototype.$dynamicDetector = null;
        }
        setLastVisitedRoute(to); // 记录用户最新浏览的页面路径
        // 需要登录未登录
        next('/login');
      } else {
        // 申请账号注销通过之后除退店页面外不能去其他页面
        if (status == '6' && to.path.indexOf('/account/logoutaccount') === -1) {
          next('/account/logoutaccount');
        }
        // else if (
        //   status == '0' &&
        //   to.path.indexOf('/account/information') === -1
        // ) {
        //   console.log('status 0');
        //   // 如果资料未完善，只能访问不需要登录和填写资料页面
        //   next('/account/information/edit');
        // }
        // else if (status == '1' && to.path != '/account') {
        //   console.log('status 0');
        //   // 如果认证待审核，可以访问不需要登录的和
        //   next('/account');
        // }
        // else if (status == '3' && to.path.indexOf('/account') === -1) {
        //   console.log('status 3');
        //   // 如果认证待审核，可以访问不需要登录的和
        //   next('/account');
        // }
        else {
          console.log('正常登录');
          next();
        }
      }
    }
  });

  app.router.afterEach((to, from) => {
    burialPoint(from, 'after', enterTime, app);
    enterTime = null;
  });
};
