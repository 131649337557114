
// 组件
import SearchBox from '@/components/comme/searchBox.vue'; //搜索
import IndexBottom from '@/components/indexbottom.vue'; //页脚
import BackToTop from '@/components/BackToTop.vue'; //回到顶部
import AdvertiseListBox from './components/AdvertiseListBox.vue'; //广告位
import PlateGoodsShowBox from './components/PlateGoodsShowBox.vue'; //板块商品容器
import PlateBar from './components/PlateBar.vue';
// api
import {
  getDetail,
  getPlateList,
  getPlateBarList,
} from '@/api/client-api/activity/customize_activities.js';
const __default__ = {
  name: 'FestivalActivity',
  components: {
    SearchBox,
    IndexBottom,
    BackToTop,
    AdvertiseListBox,
    PlateGoodsShowBox,
    PlateBar,
  },
  data() {
    return {
      // 当前活动页面标识
      activitiesType: '', //活动标识
      // 页面常规属性
      activityBanner: '', // 活动页Banner图
      activityBg: null, //活动页背景
      // 这个主要用于首次加载的板块数量
      plateListPage: {
        pageNum: 1,
        pageSize: 10,
        platePageSize: 16,
      },
      // 广告位
      advertiseList: [],
      // 板块
      plateList: [],
      // 导航栏
      plateBarList: [],
      needHighlightId: '', //需要被高亮的标签
    };
  },
  computed: {
    // 当前页面背景颜色/图片
    curActivityBg() {
      if (this.activityBg) {
        // 如果是背景图片的情况
        if (this.activityBg?.img) {
          return `url(${this.activityBg?.img})
       repeat-y center top / contain`;
        }
        // 背景色的情况
        else if (this.activityBg?.color?.length > 0) {
          let colorArr = this.activityBg?.color;
          if (colorArr?.length > 1) {
            let colorStr = `linear-gradient(180deg, ${colorArr?.join(',')})`;

            return colorStr;
          } else {
            return colorArr?.join(',');
          }
        }
      }
      return 'unset';
    },
  },
  methods: {
    /**
     * 数据处理
     */
    // 获取活动页面数据信息
    async getactivitiesDetail() {
      const options = {
        route: this.activitiesType,
      };
      try {
        let res = await getDetail(options);
        if (res?.code == 200) {
          let info = res?.data;
          this.activityBanner = info?.act_kv_img || '';
          this.activityBg = info?.background || null;
        } else if (res?.code == 30001) {
          this.$router.push('/activity_error');
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取板块信息
    async getPlateList() {
      const options = {
        route: this.activitiesType,
        pageNum: this.plateListPage?.pageNum,
        pageSize: this.plateListPage?.pageSize,
        platePageSize: this.plateListPage?.platePageSize,
      };
      try {
        let res = await getPlateList(options);
        if (res?.code == 200) {
          // 板块总数
          const allCount = res?.data?.count || 0;
          // 广告位相关
          let advertise =
            JSON.parse(JSON.stringify(res?.data?.advertise || [])) || [];
          advertise?.forEach((item) => {
            this.addShowAdvertise(item);
          });

          // 板块相关
          let plateList =
            JSON.parse(JSON.stringify(res?.data?.plate || [])) || [];
          plateList?.forEach((item) => {
            this.addPlateList(item);
          });
          const allLength =
            Number(this.advertiseList?.length || 0) +
            Number(this.plateList?.length || 0);

          if (allCount > allLength) {
            // 如果当前板块总数比真正总数小的情况，自动加载后面的数据
            this.plateListPage.pageNum++;
            this.getPlateList();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 广告位数据的添加
    addShowAdvertise(item) {
      let idx = this.advertiseList?.findIndex(
        (adItem) => adItem?.id === item?.id
      );
      if (idx == -1) {
        this.advertiseList?.push(item);
      }
    },
    // 板块商品容器数据的添加
    addPlateList(item) {
      let idx = this.plateList?.findIndex(
        (plateItem) => plateItem?.id === item?.id
      );
      if (idx == -1) {
        this.plateList?.push(item);
      }
    },

    // 获取活动页侧边栏数据
    async getPlateBarList() {
      const options = {
        route: this.activitiesType,
      };
      try {
        let res = await getPlateBarList(options);
        if (res?.code == 200) {
          this.plateBarList = res?.data || [];
        }
      } catch (error) {
        console.error(error);
      }
    },

    // 滚动事件
    scrollDom() {
      for (let i = 0; i < this.plateBarList?.length; i++) {
        let info = this.plateBarList[i];
        let domRect = document.getElementById('plate-box-' + info?.id);
        if (this.isInViePortOfOne(domRect)) {
          this.needHighlightId = info?.id || '';
          return;
        } else {
          this.needHighlightId = '';
        }
      }
    },
    // 检测节点是否进入可视区域
    isInViePortOfOne(el) {
      const viewPortHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      const offsetTop = el.offsetTop;
      const scollTop = document.documentElement.scrollTop;

      const top = offsetTop - scollTop; //900 是板块高度
      console.log(
        viewPortHeight,
        offsetTop,
        scollTop,
        top,
        top <= viewPortHeight && top >= 0
      );

      return top <= viewPortHeight && top >= -540; // 540 是板块高度的一般 加上 板块间距
    },

    // 点击导航数据滚动到对应位置
    scrollDomPosition(item) {
      if (!item?.id) return;
      this.needHighlightId = item?.id || '';
      const element = document.getElementById('plate-box-' + item?.id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth', // 平滑滚动效果
        });
      }
    },
  },
  mounted() {
    if (process.client) {
      const params = this.$route?.params;
      if (params?.plateId) {
        this.activitiesType = params?.plateId;
        this.getactivitiesDetail();
        this.getPlateList();
        this.getPlateBarList();
        const scrollDom = this._debounce(this.scrollDom, 50);
        window.addEventListener('scroll', scrollDom);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollDom);
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "3ea37c20": (_vm.curActivityBg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__