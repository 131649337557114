
import GoodsTable from '@/pages/back-stage/goods-module/goods-altention/components/GoodsTable';
import RecordDetailDialog from '../RecordDetailDialog.vue';
export default {
  components: {
    GoodsTable,
    RecordDetailDialog,
  },
  data() {
    return {
      sortStr: '', //选中的排序
      sortDesc: 0,
      num: 0,
      // 预览的
      previewImgUrl: '', //预览地址
      showPreviewdoc: false, //控制预览文件
      showPreviewimg: false, //控制预览图片

      selectRow: {}, // 选中行信息
      showDetailDialog: false, // 查看详情弹窗
    };
  },
  props: {
    // 表格配置列表
    Configuration: {
      type: Array,
      default: () => {},
    },
    // 表格信息列表
    tableData: {
      type: Array,
      default: () => {},
    },
  },
  filters: {
    getCurrency(val) {
      if (!val) return '--';
      switch (val) {
        case '1':
          return '(￥)人民币';
        case '2':
          return '($)美元';
      }
    },
  },
  methods: {
    // 是否展示奖励金
    showRewardAmount(amount) {
      return Number(amount) > 0;
    },
    // 点击查看原因/查看详情
    handleOpenDetailDialog(row) {
      this.selectRow = {
        ...row,
        actual_amount: (row?.currency_str || '') + row?.actual_amount_abs,
      };
      this.showDetailDialog = true;
    },
    // 关闭查看原因/查看详情
    handleCloseDetailDialog() {
      this.showDetailDialog = false;
      this.selectRow = {};
    },
    // 关闭查看原因弹框
    handleCloseOverrulDialog() {
      this.refuse_reason = '';
      this.showOverruleDialog = false;
    },
    // 预览
    previewBtn(row) {
      if (!row) return;
      if (row.img == '') {
        return;
      }
      let fileType = row.img.split('.').pop();
      let isImage = this.matchType(fileType).toLowerCase() == 'image';
      let isVideo = this.matchType(fileType).toLowerCase() == 'video';
      if (isImage || isVideo) {
        this.showPreviewimg = true;
      } else {
        this.showPreviewdoc = true;
      }
      this.previewImgUrl = this.$options.filters.imgbaseurl(row.img);
    },
    // 关闭预览弹框
    previewCloseFunc() {
      this.showPreviewdoc = false;
      this.showPreviewimg = false;
      this.previewImgUrl = '';
    },
    // 跳转订单详情
    goOrderDetail(row) {
      if (!row || row?.order_id == '' || row?.order_id == '--') return;
      console.log(row, 'row');
      // 如果是退款  去退款详情
      if (row.transaction_type_code == '2') {
        this.$router.push({
          path: '/trade/disputes',
          query: { item_id: row?.aftersales_bn },
        });
      }
      if (row.transaction_type_code == '1') {
        // 正常订单去订单详情
        this.$router.push({
          path: '/trade/orderdetails',
          query: { item_id: row?.order_id },
        });
      }
    },
    // 排序
    sortListBefore(type = '') {
      this.num++;
      this.sortStr = type;
      if (this.sortStr != '') {
        if (this.sortDesc != 1) {
          this.sortDesc = 1;
        } else {
          this.sortDesc = 2;
        }
      } else {
        this.sortDesc = 0;
      }
      const options = {
        sortStr: type,
        sortDesc: this.sortDesc,
      };
      this.$emit('sort-list', options);
    },
    reSort(sortStr = '', sortDesc = 0) {
      this.sortStr = sortStr; //选中的排序
      this.sortDesc = sortDesc;
      this.num++;
    },
  },
};
