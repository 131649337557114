// 充值页面配置
export const RECHARGE_TYPE = [
  { label: '余额', value: 'balance' },
  { label: '活动保证金', value: 'activity' },
  { label: '运费保证金', value: 'freight' },
  { label: '分销保证金', value: 'distribution' },
];

// 余额充值支付方式
export const BANLANCE_RECHARGE_WAY = [
  {
    type: 3, // 银行卡支付
    icon: require('@/assets/bankCard.png'),
    name: '银行卡支付',
  },
  {
    type: 1, // 微信支付
    icon: require('@/assets/weixin.png'),
    name: '微信支付',
  },
  {
    type: 2, // 支付宝支付
    icon: require('@/assets/zhifubao.png'),
    name: '支付宝支付',
  },
];

// 保证金充值支付方式
export const DEPOSIT_RECHARGE_WAY = [
  {
    type: 1, // 余额支付
    icon: require('@/assets/balance.png'),
    name: '可用余额',
    sign: 'balance',
  },
  {
    type: 6, // 微信支付
    icon: require('@/assets/weixin.png'),
    name: '微信支付',
  },
  {
    type: 3, // 支付宝支付
    icon: require('@/assets/zhifubao.png'),
    name: '支付宝支付',
  },
];
