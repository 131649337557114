
export default {
  props: {
    // 侧边栏数据
    plateBarList: {
      type: Array,
      default: () => [],
    },
    // 需要被高亮
    highlightId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      curClickId: '',
    };
  },
  watch: {
    highlightId(newVal) {
      this.curClickId = newVal;
    },
  },
  methods: {
    /**
     * 页面触发
     */
    changeItem(item) {
      this.$emit('roll-position', item);
    },
  },
};
