
export default {
  name: 'UploadProgressDialog',
  props: {
    // 上传状态 0: 未开始，3: fail, 1: uploading, 2: success
    uploadStatus: {
      type: [Number, String],
      default: 0,
    },
    // 上传进度
    uploadProgress: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // 样式配置
      styleMap: {
        1: {
          btn: '取消',
          title: '上传进度',
          icon: '',
        },
        2: {
          btn: '查看下单进度',
          title: '上传成功！',
          icon: 'icon-a-Group427320624',
        },
        3: {
          btn: '重新上传',
          title: '上传失败！',
          icon: 'icon-Subtract-1',
        },
      },
    };
  },
  computed: {
    // 当前的按钮文案
    currBtn() {
      // 特殊处理进度条为 100% 的情况，此时按钮应为返回
      if (this.uploadStatus == 1 && this.uploadProgress == 100) return '返回';

      return this.styleMap['' + this.uploadStatus]?.btn;
    },
    // 当前的弹窗标题
    currTitle() {
      return this.styleMap['' + this.uploadStatus]?.title;
    },
    // 当前的弹窗顶部图标
    currIcon() {
      return this.styleMap['' + this.uploadStatus]?.icon;
    },
  },
  methods: {
    // 点击底部的按钮
    handleClickBtn() {
      // 如果是上传中状态，则先取消上传
      if (this.uploadStatus == 0) {
        this.$emit('abort-upload');
      } else if (this.uploadStatus == 2) {
        // 上传成功点击按钮打开下单结果弹窗
        this.$emit('upload-success');
      } else if (this.uploadStatus == 3) {
        this.$emit('upload-fail');
      } else {
        this.$emit('close');
      }
    },
    // 点击弹窗右上角关闭按钮
    handleClickClose() {
      this.$emit('close');
    },
  },
};
