import { render, staticRenderFns } from "./RegisterShop.vue?vue&type=template&id=7525a2bd&scoped=true"
import script from "./RegisterShop.vue?vue&type=script&lang=js"
export * from "./RegisterShop.vue?vue&type=script&lang=js"
import style0 from "./RegisterShop.vue?vue&type=style&index=0&id=7525a2bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7525a2bd",
  null
  
)

export default component.exports