/**
 * 充值美元人民币设置
 * balance.vue
 */
export const BALANCE_RECHARGE = {
  'zh-cn': {
    'cash-desk': '收银台',
    'current-payment': '当前支付币种为',
    currency: '人民币',
    'recharge-amount': '充值金额',
    'payment-amount': '支付金额',
    'money-type': '元',
    'money-font': '￥',
    'balance-after-recharge': '充值后余额',
    'immediate-pay': '立即支付',
    'input-amount-tip': '请输入充值金额',
    'submit-success': '提交成功',
    'audit-tip': '平台将在3个工作日内审核，请留意资金余额',
  },
  en: {
    'cash-desk': 'Cash desk',
    'current-payment': 'Current payment currency',
    currency: 'USD',
    'recharge-amount': 'Recharge amount',
    'payment-amount': 'Payment amount',
    'money-type': '$',
    'money-font': '$',
    'balance-after-recharge': 'Balance after recharge',
    'immediate-pay': 'immediate payment',
    'input-amount-tip': 'Please enter the recharge amount',
    'submit-success': 'Submitted successfully',
    'audit-tip':
      'The platform will be reviewed within 3 working days, please pay attention to the fund balance',
  },
};
