/**
 * 预览各种从zip压缩包中解压出的文件的工具集合
 * 通过jszip解析出指定文件的blob文件流，再通过blob文件流预览文件
 * @author hukeyi
 * @description pdf, docx, xlsx, xls, jpg, jpeg, png, txt
 */

import {
  previewImageBlob,
  previewPdfBlob,
  previewWordBlob,
  previewExcelBlob,
  previewTxtBlob,
} from './preview-blob-files';

import { previewOffice } from './preview-url-files';

import { uploadFile } from './utils';

/**
 * 预览图片
 * @description 支持jpg，jpeg，png
 * @param { JSZip } jszip jszip压缩工具实例
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewImageZip(jszip, filePath, containerId) {
  return new Promise((resolve, reject) => {
    jszip
      .file(filePath)
      .async('blob')
      .then((blob) => {
        previewImageBlob(blob, containerId)
          .then((res) => {
            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch(function (err) {
        console.error('预览文件失败：', err);
        return reject(err);
      });
    // 换用 async-await 后（下面几行），会导致预览图片失败，原因未知
    // try {
    //   const blob = jszip.file(filePath).async('blob');
    //   await previewImageBlob(blob, containerId);
    //   return resolve();
    // } catch (err) {
    //   console.error('预览文件失败', err);
    //   return reject(err);
    // }
  });
}

/**
 * 预览pdf
 * @description 支持pdf文件
 * @param { JSZip } jszip jszip压缩工具实例
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewPdfZip(jszip, filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await jszip.file(filePath).async('blob');
      await previewPdfBlob(blob, containerId);
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

/**
 * 预览word
 * @description 支持docx文件
 * @param { JSZip } jszip jszip压缩工具实例
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewWordZip(jszip, filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await jszip.file(filePath).async('blob');
      await previewWordBlob(blob, containerId);
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

/**
 * 预览doc
 * @description 支持doc文件
 * @param { JSZip } jszip jszip压缩工具实例
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewOfficeZip(jszip, filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await jszip.file(filePath).async('blob');
      const res = await uploadFile(blob, filePath);
      let url = res.data.link;
      await previewOffice(
        process.env.NUXT_ENV.VUE_APP_STATIC_HOST + url,
        containerId
      );
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

/**
 * 预览excel
 * @description 支持xlsx和xls文件
 * @param { JSZip } jszip jszip压缩工具实例
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewExcelZip(jszip, filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await jszip.file(filePath).async('blob');
      const res = await uploadFile(blob, filePath);
      let url = res.data.link;
      await previewOffice(
        process.env.NUXT_ENV.VUE_APP_STATIC_HOST + url,
        containerId
      );
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

/**
 * 预览txt
 * @description 支持txt文件
 * @param { JSZip } jszip jszip压缩工具实例
 * @param { String } filePath 文件路径
 * @param { String } containerId 预览文件要插入的dom元素的id
 * @returns { Blob } 文件流
 */
function previewTxtZip(jszip, filePath, containerId) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await jszip.file(filePath).async('blob');
      previewTxtBlob(blob, containerId);
      return resolve();
    } catch (err) {
      console.error('预览文件失败', err);
      return reject(err);
    }
  });
}

export {
  previewImageZip,
  previewPdfZip,
  previewWordZip,
  previewExcelZip,
  previewTxtZip,
  previewOfficeZip,
};
