
import ListTab from './components/list-tab/ListTab'; //标签
import SearchBox from './components/search-box/SearchBox'; //搜索
import DataKanban from './components/data-kanban/DataKanban'; //数据看板
// 表格相关
import TableList from './components/table-list/TableList'; //表格
import { billDetailsTable, depositAccount } from './js/tableConfig';
import {
  getTransactionList,
  getSecurityDepositList,
  getTransactionSummary,
} from '@/api/client-api/fund/transactions.js';
// 工具
import dayjs from 'dayjs';
export default {
  components: {
    ListTab,
    SearchBox,
    DataKanban,
    TableList,
  },
  data() {
    return {
      activeName: 1,
      tabConfig: [
        { id: 1, name: '账单明细' },
        { id: 2, name: '保证金明细' },
      ],
      // 搜索相关
      searchData: {}, //搜索
      // 汇总相关
      count: '0', //交易汇总
      currency: '1', //币种 1人民币，2美元
      dataInfo: {}, //汇总数据
      // 分页相关
      listTotal: 0, // 表格数据总数
      pageNum: 1, // 第几页
      pageSize: 10, // 页大小
      // 表格相关
      tableData: [],
    };
  },
  computed: {
    // 表格配置
    currTableFormat() {
      if (this.activeName == 1) {
        return billDetailsTable;
      }
      if (this.activeName == 2) {
        return depositAccount;
      }
    },
    // 根据币种展示不同的单位
    moneyUnit() {
      if (this.currency == '1') {
        return '元';
      } else {
        return '美元';
      }
    },
    // 获取时间选择器默认时间，默认近三十天
    getNearMonthTime() {
      let currentTime = dayjs().format('YYYY-MM-DD') + ' 23:23:59';
      let lastMonthTime =
        dayjs().subtract(29, 'day').format('YYYY-MM-DD') + ' 00:00:00';
      return [lastMonthTime, currentTime];
    },
  },
  methods: {
    /**
     * 页面触发
     */
    // 表格内搜索
    sortList(data) {
      this.searchData.sortStr = data.sortStr;
      this.searchData[data.sortStr] = data.sortDesc;
      this.pageNum = 1;
      this.getList();
    },
    // 重置表格内筛选
    reSortList() {
      if (this.$refs?.TableList) {
        this.$refs?.TableList?.reSort();
      }
    },
    // 切换列表标签页
    choseTab(id) {
      if (this.activeName == id) {
        return;
      }
      this.activeName = id;
      if (this.$refs?.searchbox) {
        this.$refs?.searchbox.handleClickReset();
      }
    },
    // 搜索组件传回来的值
    handleSearch(data) {
      this.searchData = { ...data };
      this.pageNum = 1;
      this.getList();
      if (this.activeName == 1) {
        this.getData();
      }
      this.reSortList();
    },
    // 切换币种
    changeCurrency(index) {
      if (this.currency == index) {
        return;
      }
      this.currency = index;
      this.pageNum = 1;
      this.getList();
      if (this.activeName == 1) {
        this.getData();
      }

      this.reSortList();
    },
    // 获取汇总数据
    getData() {
      this.dataInfo = {};
      const options = {
        ...this.searchData,
        currency: this.currency,
      };
      getTransactionSummary(options)
        .then((res) => {
          if (res?.errcode == 0) {
            this.dataInfo = res?.data || {};
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * 筛选条件回显
     * @param {Object} info 当前页面缓存的搜索条件
     */
    echoSearchData(info) {
      this.pageNum = info?.pageNum || 1;
      this.currency = info?.currency || '1';
      // 标签页
      this.activeName = info?.activeName || 1;
      this.searchData = { ...info };
      this.$nextTick(() => {
        if (info?.sortStr) {
          this.$refs?.TableList?.reSort(info?.sortStr, info?.[info?.sortStr]);
        }
        if (this.$refs?.searchbox) {
          this.$refs?.searchbox?.echoSearchData(info);
        }
      });
    },
    /**
     * 获取列表
     * @param {Boolean} isCache 控制是否对筛选条件进行缓存
     */
    async getList(isCache = true) {
      try {
        const options = {
          ...this.searchData,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          currency: this.currency,
          MUTE_WARNING: 1,
          activeName: this.activeName,
        };
        let res;
        if (this.activeName == 1) {
          res = await getTransactionList(options);
        }
        if (this.activeName == 2) {
          res = await getSecurityDepositList(options);
        }
        if (isCache) {
          this.setPageFilter(options);
        }
        if (res?.errcode == 0) {
          this.tableData = res?.data?.list || [{}];
          this.listTotal = Number(res?.data?.count) || 0;
          this.count = Number(res?.data?.count) || 0;
          if (this.pageNum > 1 && this.tableData?.length == 0) {
            console.log('交易记录');
            this.goPage(1);
          }
        } else {
          this.tableData = [];
          this.listTotal = 0;
        }
      } catch (err) {
        console.error(err);
      }
    },
    goPage(val, pageSize) {
      this.pageNum = val;
      console.log('交易记录-page', this.pageNum);
      if (pageSize) {
        this.pageSize = pageSize;
      }
      this.getList();
    },
  },
  mounted() {
    if (process.client) {
      this.$nextTick(() => {
        // 获取当前页面筛选缓存
        let info =
          JSON.parse(JSON.stringify(this.getPageFilterData() || {})) || {};
        if (!info?.create_start) {
          info.create_start = this.getNearMonthTime?.[0];
          info.end_start = this.getNearMonthTime?.[1];
        }
        if (info) {
          this.echoSearchData(info);
        }
        this.getList(false);
        this.getData();
      });
    }
  },
};
