
// 组件
import GoodsCardsBox from './GoodsCardsBox.vue';
// api
import { getPlateItemList } from '@/api/client-api/activity/customize_activities.js';
const __default__ = {
  components: {
    GoodsCardsBox,
  },
  props: {
    // 当前活动页标识
    activitiesType: {
      type: String,
      default: '',
    },
    // 板块数据信息
    plateInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      scrollOffset: 0, // 当前滚动的距离
      scrollNum: 0, // 滚动的次数
      goodsList: [],
      pageNum: 2,
      pageSize: 8,
      plateItemCount: this.plateInfo?.plateItemCount || 0,
    };
  },
  computed: {
    // 当前板块背景图
    curPlateBg() {
      if (this.plateInfo?.plate_img) {
        return `url(${this.plateInfo?.plate_img}) no-repeat`;
      }
      return 'unset';
    },
    // 当前列表应有长度
    goodsListLength() {
      return Math.ceil(Number(this.plateItemCount) / 8) || 1;
    },
  },
  methods: {
    /**
     * 数据处理
     */
    // 设置分页数据
    setPageList() {
      let firstArray = [];
      let firstList = this.plateInfo?.plateItemList || [];
      if (firstList?.length <= 8) {
        firstArray[0] = firstList;
      } else {
        // 将数据变为两页
        firstArray[0] = firstList?.slice(0, 8);
        firstArray[1] = firstList?.slice(8, firstList?.length);
      }
      this.goodsList = firstArray;
    },

    /**
     * 页面触发
     */
    // 点击向左滚动
    scrollLeft() {
      if (!this.scrollOffset) return;
      this.scrollOffset = this.scrollOffset + 1020;
      this.scrollNum--;
    },
    // 点击向右滚动
    scrollRight() {
      // 滚动次数等于数组应有长度-1 即滚动完成
      if (this.scrollNum == this.goodsListLength - 1) return;
      // 如果当前的数据长度小于应有的长度，即需要获取接口数据
      if (this.goodsList.length < Number(this.goodsListLength)) {
        this.pageNum++;
        this.getAfterGoodsData();
      }
      this.scrollOffset = this.scrollOffset - 1020;
      this.scrollNum++;
    },

    // 获取后续的数据
    async getAfterGoodsData() {
      let lengths = this.goodsList?.length;

      let options = {
        route: this.activitiesType,
        plate_id: this.plateInfo?.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        MUTE_WARNING: 1,
      };
      let res = await getPlateItemList(options);
      if (res?.code == 200) {
        if (res?.data?.list) {
          this.goodsList[lengths] = res?.data?.list;
        }
        this.plateItemCount = Number(res?.data?.count || 0);
      }
    },
  },
  mounted() {
    this.setPageList();
    // 防抖
    this.scrollLeftBtn = this._debounce(this.scrollLeft, 300);
    this.scrollRightBtn = this._debounce(this.scrollRight, 300);
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "92069762": (_vm.curPlateBg)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__