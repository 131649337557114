
// 工具函数
import { CUR_TOKEN_NAME } from '@/utils/cur_default_config.js';
import { mapMutations } from 'vuex';
import tool from '@/utils/tool-function/tool.js';
export default {
  data() {
    return {};
  },
  methods: {
    ...mapMutations('login', [
      'setToken',
      'setUserInfo',
      'setLoginAllFormByKey',
    ]),
    /**
     * 页面触发
     */
    // 对链接数据进行对应操作
    async processData() {
      let query = this.$route?.query;
      if (query?.logintype == 'logout') {
        tool.removeLoginInfo();
        this.goBackUrl(query);
      } else {
        // 有临时令牌的情况
        if (query?.userId) {
          tool.removeLoginInfo();
          const options = {
            sign: query?.userId,
            MUTE_WARNING: 1,
            MUTE_ERROR: 1,
          };
          try {
            // 通过临时token获取真正令牌(获取用户信息)
            let res = await this.$getToken(options);
            if (res?.errcode == 0) {
              let token = res?.data?.token;
              let userInfo = res?.data;
              const dataInfo = {
                token: token,
                user: userInfo,
              };
              this.handleSaveLocalStorageShop(dataInfo);
              this.goBackUrl(query);
            } else {
              this.goBackUrl(query);
            }
          } catch (error) {
            console.error(error);
            this.goBackUrl(query);
          }
        } else {
          // 没有临时令牌的情况
          this.goBackUrl(query);
        }
      }
    },
    // 返回到之前页面（不要嫌放的地方多，因为一定要就扣响应了，才进行跳转，才能确保存到数据（因为出现了存储失败的情况（概率性）））
    goBackUrl(query) {
      // 如果有返回链接的情况
      if (query?.redirectURL) {
        window.open(query?.redirectURL, '_self');
      } else {
        window.open('/', '_self');
      }
    },
    // 分销商(对用户信息的缓存)
    handleSaveLocalStorageShop(data) {
      localStorage.setItem(
        CUR_TOKEN_NAME,
        JSON.parse(JSON.stringify(data?.token))
      );
      this.setToken(JSON.parse(JSON.stringify(data?.token)));
      // 没有用户信息的情况下，不往下走
      if (!data?.user) return;

      localStorage.setItem('userdetail', JSON.stringify(data?.user || {}));
      localStorage.setItem('statusShow', data?.user?.status);
      this.setUserInfo({
        key: 'userdetail',
        value: JSON.stringify(data?.user || {}),
      });
      this.setUserInfo({ key: 'statusShow', value: data?.user?.status });
    },
  },
  mounted() {
    if (process.client) {
      this.processData();
    }
  },
};
